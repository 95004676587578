module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"No Class Tattoo Studio","short_name":"No Class","start_url":"/","background_color":"#171717","theme_color":"#FAF0E6","display":"standalone","icon":"src/images/Favicon.jpg"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
